import { CommonModule, KeyValue } from '@angular/common';
import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
  input,
} from '@angular/core';
import {
  DashboardDetailsFacade,
  DashboardFacade,
} from '@backoffice-frontend/market/domain';
import {
  BaseChartWithMultipleTimeSeries,
  BaseChartWithMultipleTimeSeriesParams,
  ChartTimeSeries,
  TimeSeriesFacade,
  TimeSeriesInput,
  TimeSeriesPoint,
} from '@backoffice-frontend/time-series-identifier/domain';
import { indicate } from '@clean-code/shared/common';
import {
  DevExtremeLineChartComponent,
  LineChartModel,
} from '@clean-code/shared/components/ui-devextreme-charts';
import { ProgressBarModule } from '@clean-code/shared/components/ui-progress-bar';
import { TranslocoModule } from '@jsverse/transloco';
import { filter, map, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'fof-comparison-chart',
  styleUrls: ['./comparison-chart.component.scss'],
  templateUrl: './comparison-chart.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,

    TranslocoModule,

    DevExtremeLineChartComponent,
    ProgressBarModule,
  ],
})
export class ComparisonChartComponent extends BaseChartWithMultipleTimeSeries {
  @HostBinding('class') class = 'fof-comparison-chart';

  @Input({ required: true })
  public set params(value: BaseChartWithMultipleTimeSeriesParams) {
    this.params$.next(value);
  }

  legend = input(true);

  public data$ = this.paramChange$.pipe(
    filter((params: BaseChartWithMultipleTimeSeriesParams) => !!params),
    switchMap((params: BaseChartWithMultipleTimeSeriesParams) => {
      return this.timeSeriesFacade
        .chartComparisonTimeSeries$(
          params.identifiers.map((x: TimeSeriesInput) => x.identifier),
          params.timeFrame,
          params.showInPercentage,
        )
        .pipe(
          map((values: ChartTimeSeries[]) => {
            const array = new Array<LineChartModel>();
            const table = new Array<{
              name: string;
              value: number;
              unit: string;
            }>();

            values.forEach((value: ChartTimeSeries) => {
              let elementWithLatestDate: TimeSeriesPoint;
              const data = {
                label: value.frontOfficeName,
                data: value.values.map((val: TimeSeriesPoint) => {
                  if (
                    !elementWithLatestDate?.tradeDate ||
                    val.tradeDate.getTime() >
                      elementWithLatestDate.tradeDate.getTime()
                  ) {
                    elementWithLatestDate = val;
                  }
                  return {
                    date: new Date(val.tradeDate),
                    value: val.value,
                  };
                }),
                yAxisTitle: value.unit,
              } as LineChartModel;

              if (params.showTable) {
                table.push({
                  name: value.frontOfficeName,
                  value: elementWithLatestDate?.value ?? 0,
                  unit: value.unit,
                });
              }

              if (value.error) {
                data.error = {
                  key: value.frontOfficeName,
                  value: value.error.message,
                } as KeyValue<string, string>;
              }

              array.push(data);
            });

            return { chart: array, table };
          }),
          indicate(this.isLoading$),
        );
    }),
  );

  constructor(
    private timeSeriesFacade: TimeSeriesFacade,
    public dashboardFacade: DashboardFacade,
    public marketDashboardDetailsDataService: DashboardDetailsFacade,
  ) {
    super();
  }
}
