import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
  input,
} from '@angular/core';
import type { TimeFrame } from '@backoffice-frontend/shared/bo/util-masterdata';
import type {
  BaseChartWithMultipleTimeSeriesParams,
  InputData,
  TimeSeriesInput,
} from '@backoffice-frontend/time-series-identifier/domain';
import { TimeSpanToggleComponent } from '@clean-code/shared/components/ui-time-span-toggle';
import { provideCustomNativeDateAdapter } from '@clean-code/shared/util/util-date';
import { ComparisonChartComponent } from '@clean-code/time-series-identifier/ui-comparison-chart';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AroonChartComponent } from './components/aroon-chart/aroon-chart.component';
import { BollingerBandChartComponent } from './components/bollinger-band-chart/bollinger-band-chart.component';
import { IndexChartComponent } from './components/index-chart/index-chart.component';
import { MAChartComponent } from './components/ma-chart/ma-chart.component';
import { SpotChartComponent } from './components/spot-chart/spot-chart.component';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'market-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,

    TimeSpanToggleComponent,
    MarketDetailsComponent,
    IndexChartComponent,
    AroonChartComponent,
    MAChartComponent,
    BollingerBandChartComponent,
    MAChartComponent,
    SpotChartComponent,
    ComparisonChartComponent,
  ],
  providers: [provideCustomNativeDateAdapter()],
})
export class MarketDetailsComponent {
  @HostBinding('class') class = 'market-details';

  public dataSource$ = new BehaviorSubject<TimeSeriesInput>(null);
  public selectedTimeFrame$ = new BehaviorSubject<TimeFrame>('M_3');

  public deliveryDay = false;
  public legend = input(true);

  public comparisonData$ = combineLatest([
    this.dataSource$,
    this.selectedTimeFrame$,
  ]).pipe(
    filter(
      ([dataSource]: [TimeSeriesInput, TimeFrame]) =>
        dataSource.additionalDataSources.length > 0,
    ),
    map(([dataSource, timeFrame]: [TimeSeriesInput, TimeFrame]) => {
      const identifiers = (
        dataSource.additionalDataSources.flat(2) as unknown as InputData[]
      ).map((v: InputData) => v.settings);
      return {
        identifiers: identifiers,
        timeFrame: timeFrame,
      } as BaseChartWithMultipleTimeSeriesParams;
    }),
  );

  @Input()
  set dataSource(value: TimeSeriesInput) {
    if (value?.timeFrame) {
      this.selectedTimeFrame$.next(value?.timeFrame);
    } else if (value?.identifier?.adjustment < 0) {
      //change timeframe to one year if identifier is in the past!
      //else there might be no data visible on open
      this.selectedTimeFrame$.next('Y_1');
    }
    this.dataSource$.next(value);
  }

  @Input()
  public hideClosingValueHeader = false;

  public deliveryDayChanged(value: boolean) {
    if (this.deliveryDay != value) {
      this.deliveryDay = value;
    }
  }
}
